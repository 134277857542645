export function calculRataLunaraEgala(
  principal: number,
  rataDobanzii: number,
  nrLuni: number
) {
  if (rataDobanzii === 0) {
    return Math.round((principal * 100) / nrLuni) / 100;
  }

  rataDobanzii = rataDobanzii / 12; // one month
  rataDobanzii = (rataDobanzii * 365) / 360;

  var rataLunara =
    (principal * rataDobanzii * Math.pow(1 + rataDobanzii, nrLuni)) /
    (Math.pow(1 + rataDobanzii, nrLuni) - 1);

  return rataLunara;
}

export function calculatorRate(
  sumaDisbursata: number,
  rataDobanzii: number,
  nrLuni: number,
  comisionAcordare: number,
  comisionLunar: number
) {
  const sumaAcordata =
    (comisionAcordare * sumaDisbursata) / (1 - comisionAcordare) +
    sumaDisbursata;
  const comisionUpfront = comisionAcordare * sumaAcordata;
  let scadentar = new Array(nrLuni);

  const rataLunaraEgalaFaraComision = calculRataLunaraEgala(
    sumaAcordata,
    rataDobanzii,
    nrLuni
  );
  const coefDobanda = (rataDobanzii * 365) / 360 / 12;
  let totalComisioaneLunare = 0;
  let totalDobanda = 0;
  for (let i = 0; i < nrLuni; i++) {
    if (i === 0) {
      scadentar[i] = {
        luna: i + 1,
        sold: sumaAcordata,
        dobanda: coefDobanda * sumaAcordata,
      };
      scadentar[i].principal =
        rataLunaraEgalaFaraComision - scadentar[i].dobanda;
      scadentar[i].rataLunaraEgalaFaraComision = rataLunaraEgalaFaraComision;
      scadentar[i].sold = sumaAcordata - scadentar[i].principal;
      scadentar[i].comsion = comisionLunar * sumaAcordata;
      scadentar[i].rataLunaraEgala = 0;

      totalComisioaneLunare += scadentar[i].comsion;
      totalDobanda += scadentar[i].dobanda;
    } else if (i < nrLuni - 1) {
      scadentar[i] = {
        luna: i + 1,
      };
      scadentar[i].dobanda = coefDobanda * scadentar[i - 1].sold;
      scadentar[i].principal =
        rataLunaraEgalaFaraComision - scadentar[i].dobanda;
      scadentar[i].sold = scadentar[i - 1].sold - scadentar[i].principal;
      scadentar[i].rataLunaraEgalaFaraComision = rataLunaraEgalaFaraComision;
      scadentar[i].comsion = comisionLunar * scadentar[i - 1].sold;
      scadentar[i].rataLunaraEgala = 0;

      totalComisioaneLunare += scadentar[i].comsion;
      totalDobanda += scadentar[i].dobanda;
    } else {
      scadentar[i] = {
        luna: i + 1,
      };
      scadentar[i].dobanda = coefDobanda * scadentar[i - 1].sold;
      scadentar[i].principal =
        rataLunaraEgalaFaraComision - scadentar[i].dobanda;
      scadentar[i].sold = scadentar[i - 1].sold - scadentar[i].principal;
      scadentar[i].rataLunaraEgalaFaraComision = rataLunaraEgalaFaraComision;
      scadentar[i].comsion = comisionLunar * scadentar[i - 1].sold;
      scadentar[i].rataLunaraEgala = 0;

      totalComisioaneLunare += scadentar[i].comsion;
      totalDobanda += scadentar[i].dobanda;
    }
  }

  let comisionMediu = totalComisioaneLunare / nrLuni;

  //calcul rataLunaraEgala
  for (let i = 0; i < nrLuni; i++) {
    scadentar[i].rataLunaraEgala =
      scadentar[i].rataLunaraEgalaFaraComision + comisionMediu;
  }

  let comisionAcordareSuma =
    sumaDisbursata / (1 - comisionAcordare) - sumaDisbursata;

  return {
    sumaAcordata: Math.round(sumaAcordata * 100) / 100,
    scadentar: scadentar,
    comisionLunarMediu: comisionMediu,
    rataLunara2zecimale: Math.round(scadentar[0].rataLunaraEgala * 100) / 100,
    rataLunara: Math.round(scadentar[0].rataLunaraEgala),
    costTotal: Math.round(
      scadentar[0].rataLunaraEgala * nrLuni + comisionUpfront - sumaAcordata
    ),
    totalDobanda: Math.round(totalDobanda),
    totalComisioaneLunare: Math.round(totalComisioaneLunare),
    comisionAcordareSuma: Math.round(comisionAcordareSuma),
    comisionUpfront: comisionUpfront,
  };
}

export const validateCIF = (cif: string) => {
  if (cif) {
    cif = cif.trim();
    cif = cif.replace(/ /g, "");

    cif = cif.toUpperCase();
    if (cif.substring(0, 2) === "RO") {
      cif = cif.substring(2);
    }
  } else {
    cif = "";
  }

  // daca are mai mult de 10 cifre sau mai putin de 2, nu-i valid
  if (cif.length > 10 || cif.length < 2) {
    return false;
  }

  var pattern = /^\d+$/;
  if (!pattern.test(cif)) return false;
  let cifNum = parseInt(cif);
  // numarul de control
  let v = 753217532;
  // extrage cifra de control
  const c1 = cifNum % 10;
  cifNum = Math.floor(cifNum / 10);
  // executa operatiile pe cifre
  var t = 0;
  while (cifNum > 0) {
    t += (cifNum % 10) * (v % 10);
    cifNum = Math.floor(cifNum / 10);
    v = Math.floor(v / 10);
  }

  // aplica inmultirea cu 10 si afla modulo 11
  var $c2 = (t * 10) % 11;
  // daca modulo 11 este 10, atunci cifra de control este 0
  if ($c2 === 10) {
    $c2 = 0;
  }

  return c1 === $c2;
};

export function generateUUID() {
  var d = new Date().getTime(); //Timestamp
  var d2 = (performance && performance.now && performance.now() * 1000) || 0;
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
    var r = Math.random() * 16;
    if (d > 0) {
      r = (d + r) % 16 | 0;
      d = Math.floor(d / 16);
    } else {
      r = (d2 + r) % 16 | 0;
      d2 = Math.floor(d2 / 16);
    }
    return (c === "x" ? r : (r & 0x3) | 0x8).toString(16);
  });
}
