import React, { FC, useState, useEffect, useContext, useCallback } from "react";
import { User, Profile, Log } from "oidc-client";
import { useHistory, useLocation } from "react-router";

import { AuthContextProps } from "./AuthContextInterface";
import jwt_decode from "jwt-decode";
import { globalAxiosClient } from "modules/Shared/api/client";
import { MasterUserManager, MasterUser } from "../userManager/MasterUserManager";
import { isRunningFromWebView } from "modules/Shared/utils";

export const AuthContext = React.createContext<AuthContextProps | null>(null);

const routeIsOIDCCodeCallback = (): boolean => {
  const searchParams = new URLSearchParams(window.location.search);
  const hashParams = new URLSearchParams(
    window.location.hash.replace("#", "?")
  );
  return Boolean(
    window.location.pathname.toLowerCase().startsWith(`/callback`) &&
    (searchParams.get("code") ||
      searchParams.get("id_token") ||
      searchParams.get("session_state") ||
      hashParams.get("code") ||
      hashParams.get("id_token") ||
      hashParams.get("session_state"))
  );
};

export const useAuthContext = () => {
  return useContext<AuthContextProps | null>(AuthContext);
};

export const AuthProvider: FC = ({ children }) => {
  Log.logger = console;
  Log.level = Log.WARN;

  const [userManager, setUserManager] = useState<MasterUserManager | null>(null);
  const [user, setUser] = useState<MasterUser | null | undefined>(null);
  const [logoutStarted, setlogoutStarted] = useState<Boolean>(false);

  function getUserManagerLazy() {
    const masterUserManager = userManager;
    if (userManager === null) {
      const masterUserManager = new MasterUserManager().initUserManager();
      setUserManager(masterUserManager);
    }
    return masterUserManager;
  }

  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    const userManager = getUserManagerLazy();
    const getUser = async (): Promise<void> => {
      if (isRunningFromWebView()) {
        await userManager?.getUser().then((productRequestUser) => {
          productRequestUser && setUser(productRequestUser);
        })
        return;
      }

      //If the flow is returning back from OIDC callback it should obtain idToken, accesToken, userPofile and store them in session storage
      if (routeIsOIDCCodeCallback()) {
        window.analytics?.trackEvent(window.analytics.events.AuthCallback);
        await userManager?.signinRedirectCallback().then((user) => {
          user && setUser(user);
          window.analytics?.trackEvent(window.analytics.events.AuthCallbackSuccess);
          history.replace({ pathname: user?.state.url, search: "" });
        }).catch((error) => {
          window.analytics?.trackEvent(window.analytics.events.AuthCallbackFailed, { error: error });
          history.replace({ pathname: "/dashboard" });
        });
        return;
      }

      const autoSignIn = false;
      const user = await userManager?.getUser();
      if ((!user || userManager?.isUserExpired(user)) && autoSignIn) {
        signIn();
      }
      return;
    };

    getUser();
  }, [history, location, userManager]);

  const signIn = useCallback(async (username?: string, redirectUrl?: string): Promise<void> => {
    if (logoutStarted) {
      return;
    }
    await userManager?.signinRedirect(redirectUrl, username);
  }, [userManager, logoutStarted]);

  const signOut = useCallback(async (): Promise<void> => {
    setlogoutStarted(true);
    await userManager?.signoutRedirect();
  }, [userManager]);

  const autoLoginOverrideAfterRegistration = useCallback(
    async (
      refresh_token: string,
      access_token: string,
      id_token: string,
      successCallback?: (() => any) | undefined | null,
      failCallback?: (() => any) | undefined | null
    ): Promise<void> => {
      const decoded_access_token: any = jwt_decode(access_token);
      const decoded_id_token: any = jwt_decode(id_token);

      let profile: Profile;
      profile = {
        iss: decoded_id_token.iss,
        aud: decoded_id_token.aud,
        exp: decoded_id_token.exp,
        sub: decoded_id_token.sub,
        iat: decoded_id_token.iat,
        auth_time: decoded_id_token.auth_time,
      };

      const oidcUser = new User({
        access_token: access_token,
        expires_at: decoded_access_token.exp,
        id_token: id_token ?? "",
        profile: profile,
        refresh_token: refresh_token,
        token_type: "Bearer",
        scope: process.env.REACT_APP_AUTH_CLIENT_SCOPE!,
        session_state: "",
        state: "",
      });

      await userManager?.storeUser(oidcUser).then(() => {
        userManager?.getUser()?.then((user) => {
          window.analytics?.trackEvent(window.analytics.events.AuthTokenOverriden);
          user && setUser(user);
          successCallback && successCallback();
        });
      }, () => {
        failCallback && failCallback();
      });
    },
    [userManager]
  );

  const getAccessToken = useCallback(async (): Promise<string | undefined> => {
    const userManager = getUserManagerLazy();
    const user = await userManager?.getUser();
    if (!user) {
      window.analytics?.trackEvent(window.analytics.events.AuthAccessTokenNotFound);
      await signIn();
      return undefined;
    } else {
      return user!.access_token;
    }
  }, [userManager]);

  globalAxiosClient.interceptors.response.use(res => res, async (error) => {
    if (error?.response?.status === 401) {
      await signIn();
    }
    return Promise.reject(error);
  });

  return (
    <AuthContext.Provider
      value={{
        signIn,
        signOut,
        autoLoginOverrideAfterRegistration,
        getAccessToken,
        user
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
