import React, { Suspense, lazy, useEffect } from "react";
import ReactGA from 'react-ga';

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import NotificationProvider from "./components/Notifications/NotificationProvider";

//Components
import { AuthProvider, useAuthContext } from "./context/AuthContext";
import { AnalyticsProvider } from "modules/Shared/analytics/AnalyticsContext";
import { Helmet } from "react-helmet";
import { isRunningFromWebView } from "modules/Shared/utils";
import classNames from "classnames";

const UserRegistration = lazy(() => import(/* webpackPrefetch: true */'modules/UserRegistration/UserRegistration'));
const ProductRequest = lazy(() => import(/* webpackPrefetch: true */'modules/Product/Product'));
const Dashboard = lazy(() => import(/* webpackPrefetch: true */'modules/Dashboard/Dashboard'));
const Storybook = lazy(() => import(/* webpackPrefetch: true */'modules/Storybook/Storybook'));
const UserProfile = lazy(() => import(/* webpackPrefetch: true */'modules/UserProfile/pages/UserProfile'));
const FinancingSolution = lazy(() => import(/* webpackPrefetch: true */'modules/FinancingSolution/pages/FinancingSolution'));
const NotFound = lazy(() => import(/* webpackPrefetch: true */'modules/Shared/pages/404NotFoundPage'));
const KycUnplannedMaintenance = lazy(() => import(/* webpackPrefetch: true */'modules/Shared/pages/KycUnplannedMaintenance'));
const KycPlannedMaintenance = lazy(() => import(/* webpackPrefetch: true */'modules/Shared/pages/KycPlannedMaintenance'));
const Partner = lazy(() => import(/* webpackPrefetch: true */'modules/Partners/Partner'));
const ExternalProductRequest = lazy(() => import(/* webpackPrefetch: true */'modules/Product/pages/ExternalProductRequest'));

function PrivateRoute({ children, ...rest }: any) {
  const authContext = useAuthContext();

  const renderChildren = () => {
    const user = authContext!.user;
    if (user) {
      ReactGA.pageview(window.location.pathname + window.location.search);
      console.info(`PrivateRoute::private route ALLOWED ${rest.path} `);
      return children;
    } else if (!user) {
      console.info(
        `PrivateRoute::private route REJECTED sign in ${rest.path} `
      );
      authContext?.signIn();
    }
  };

  useEffect(() => {
    if (!isRunningFromWebView()) {
      const mediaTelScript = document.createElement('script');
      mediaTelScript.type = "text/javascript";
      mediaTelScript.textContent = `
        window._mdtCIC_configs = {
          xmppHost: "filbo.xmpp.mediatel.ro",
          bubble_title: "Bună👋! Cu ce te putem ajuta?",
          init_variables: { channel: "Filbo" },
          url: "https://filbo.xmpp.mediatel.ro/mcic",
        };
      `;
      document.head.appendChild(mediaTelScript);

      const mediaTelChatScript = document.createElement('script');
      mediaTelChatScript.type = "text/javascript";
      mediaTelChatScript.src = "https://filbo.xmpp.mediatel.ro/mcic/web/mediatel_chat.js";
      document.body.appendChild(mediaTelChatScript);
    }
  }, []);

  return (
    <>
      <Helmet>
        <title>Filbo {rest.title}</title>
      </Helmet>
      <Route {...rest} render={({ location }) => renderChildren()} />
    </>
  );
}

export default function App() {
  ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_TRACKING_CODE!);

  var appClasses = classNames(
    'app', { 'hide-scrollbar': isRunningFromWebView() }
  );

  return (
    <Router>
      <AuthProvider>
        <AnalyticsProvider>
          <NotificationProvider>
            <div className={appClasses}>
              <Suspense fallback="Loading">
                <Switch>
                  <PrivateRoute path={"/dashboard"} title="Dashboard">
                    <Dashboard />
                  </PrivateRoute>
                  <PrivateRoute path={"/profile"} title="Profile">
                    <UserProfile />
                  </PrivateRoute>
                  <PrivateRoute path={"/products"} title="Products">
                    <FinancingSolution />
                  </PrivateRoute>
                  <PrivateRoute path={"/productrequest"} title="Product Request">
                    <ProductRequest />
                  </PrivateRoute>
                  <PrivateRoute path={"/external-product-request"} title="External Product Request" component={ExternalProductRequest} />
                  <Route path={"/onboarding"}>
                    <UserRegistration />
                  </Route>
                  <Route path={"/partner"}>
                    <Partner />
                  </Route>
                  <Route path={"/notfound"}>
                    <NotFound />
                  </Route>
                  <Route path={"/kyc-unplanned-maintenance.html"}>
                    <KycUnplannedMaintenance />
                  </Route>
                  <Route path={"/kyc-planned-maintenance.html"}>
                    <KycPlannedMaintenance />
                  </Route>
                  //do not protect callback and logout routes as they are part
                  of the flow and are handled automatically by Authprovider
                  <Route path={"/callback"}></Route>
                  <Route
                    path={"/logout"}
                    render={() => <Redirect to={"/dashboard"} />}
                  ></Route>
                  <Route path={"/silentrenew"}></Route>
                  <Route
                    path="/"
                    render={() => <Redirect to={"/dashboard"} />}
                  />
                  <Route path={"/storybook"}>
                    <Storybook />
                  </Route>
                </Switch>
              </Suspense>
            </div>
          </NotificationProvider>
        </AnalyticsProvider>
      </AuthProvider>
    </Router>
  );
}
