import axios from "axios";
import config from "./config";

export const globalAxiosClient = axios.create({
  baseURL: process.env.REACT_APP_FILBO_PUBLIC_API_URL,
  headers: {
    "X-Client-SessionId": config.sessionId,
    "X-Client-ID": process.env.REACT_APP_AUTH_CLIENT_ID,
    "X-API-Key": process.env.REACT_APP_AUTH_CLIENT_API_KEY,
    "X-Language": "ro",
  }
});

globalAxiosClient.interceptors.request.use(req => req, (error) => {
  window.analytics?.trackException(error);
  return Promise.reject(error);
});

globalAxiosClient.interceptors.response.use(res => res, (error) => {
  window.analytics?.trackException(error);
  return Promise.reject(error);
});