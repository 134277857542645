import React, { useState, useContext, useCallback } from "react";
import NotificationContainer from "./NotificationContainer";
import { ToastProps } from "./Notification";

interface ToastHelpers {
  addToast: any;
  removeToast: any;
}
let id = 1;

const initialContext: ToastHelpers = {
  addToast: () => { },
  removeToast: () => { }
}
const NotificationContext = React.createContext(initialContext);

declare global {
  interface Window {
    notifications: {
      notifySuccess: (text: string, props?: any) => void;
      notifyError: (text: string, props?: any) => void;
    };
  }
}

window.notifications = window.notifications || {};

type ProviderProps = {
  children: React.ReactNode;
};

const NotificationProvider = ({ children }: ProviderProps) => {
  const [toasts, setToasts] = useState<ToastProps[]>([]);

  const addToast = useCallback(
    (content, props) => {
      setToasts((toasts: any) => [
        ...toasts,
        {
          id: id++,
          content,
          props,
        },
      ]);
    },
    [setToasts]
  );

  const removeToast = useCallback(
    (id) => {
      setToasts((toasts) => toasts.filter((t) => t.id !== id));
    },
    [setToasts]
  );

  const notifySuccess = (text: string, props: any) => {
    addToast(text, { status: "success", ...props });
  };

  const notifyError = (text: string, props: any) => {
    addToast(text, { status: "error", duration: 10000, ...props });
  };

  window.notifications = {
    notifySuccess,
    notifyError,
  };

  return (
    <NotificationContext.Provider
      value={{
        addToast,
        removeToast
      }}
    >
      <NotificationContainer toasts={toasts} />
      {children}
    </NotificationContext.Provider>
  );
};

const useNotification = () => {
  const toastHelpers = useContext(NotificationContext);
  return toastHelpers;
};

export { NotificationContext, useNotification };
export default NotificationProvider;
