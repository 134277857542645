export const OnboardingEvents = {
  StartRegistrationOpen: "StartRegistrationOpen",
  StartRegistrationSubmit: "StartRegistrationSubmit",
  StartRegistrationOK: "StartRegistrationOK",
  StartRegistrationERROR: "StartRegistrationERROR",

  ValidateEmailOpen: "ValidateEmailOpen",
  ValidateEmailSubmit: "ValidateEmailSubmit",
  ValidateEmailOK: "ValidateEmailOK",
  ValidateEmailERROR: "ValidateEmailERROR",

  RegisterPhoneOpen: "RegisterPhoneOpen",
  RegisterPhoneSubmit: "RegisterPhoneSubmit",
  RegisterPhoneOK: "RegisterPhoneOK",
  RegisterPhoneERROR: "RegisterPhoneERROR",

  ValidatePhoneOpen: "ValidatePhoneOpen",
  ValidatePhoneSubmit: "ValidatePhoneSubmit",
  ValidatePhoneOK: "ValidatePhoneOK",
  ValidatePhoneERROR: "ValidatePhoneERROR",

  SetPasswordOpen: "SetPasswordOpen",
  SetPasswordSubmit: "SetPasswordSubmit",
  SetPasswordOK: "SetPasswordOK",
  SetPasswordERROR: "SetPasswordERROR",
  SetPasswordAutoAdvanceToDashboard: "SetPasswordAutoAdvanceToDashboard",
};

export const PartnerOnboardingEvents = {
  PartnerStartRegistrationOpen: "PartnerStartRegistrationOpen",
  PartnerStartRegistrationSubmit: "PartnerStartRegistrationSubmit",
  PartnerStartRegistrationOK: "PartnerStartRegistrationOK",
  PartnerStartRegistrationERROR: "PartnerStartRegistrationERROR",

  PartnerValidateEmailOpen: "PartnerValidateEmailOpen",
  PartnerValidateEmailSubmit: "PartnerValidateEmailSubmit",
  PartnerValidateEmailOK: "PartnerValidateEmailOK",
  PartnerValidateEmailERROR: "PartnerValidateEmailERROR",

  PartnerRegisterPhoneOpen: "PartnerRegisterPhoneOpen",
  PartnerRegisterPhoneSubmit: "PartnerRegisterPhoneSubmit",
  PartnerRegisterPhoneOK: "PartnerRegisterPhoneOK",
  PartnerRegisterPhoneERROR: "PartnerRegisterPhoneERROR",

  PartnerValidatePhoneOpen: "PartnerValidatePhoneOpen",
  PartnerValidatePhoneSubmit: "PartnerValidatePhoneSubmit",
  PartnerValidatePhoneOK: "PartnerValidatePhoneOK",
  PartnerValidatePhoneERROR: "PartnerValidatePhoneERROR",

  PartnerFinishRegistrationSubmit: "PartnerFinishRegistrationSubmit",
  PartnerFinishRegistrationOK: "PartnerFinishRegistrationOK",
  PartnerFinishRegistrationERROR: "PartnerFinishRegistrationERROR",
  PartnerFinishRegistrationAutoAdvanceToProduct:
    "PartnerFinishRegistrationAutoAdvanceToProduct",

  PartnerGetPartnerDetailsSubmit: "PartnerGetPartnerDetailsSubmit",
  PartnerGetPartnerDetailsOK: "PartnerGetPartnerDetailsOK",
  PartnerGetPartnerDetailsERROR: "PartnerGetPartnerDetailsERROR",

  PartnerAnonymousStartRegistrationOpen:
    "PartnerAnonymousStartRegistrationOpen",
  PartnerAnonymousStartCustomerRegistration:
    "PartnerAnonymousStartCustomerRegistration",
  PartnerAnonymousCustomerRegistrationOK:
    "PartnerAnonymousCustomerRegistrationOK",
  PartnerAnonymousCustomerRegistrationERROR:
    "PartnerAnonymousCustomerRegistrationERROR",
  PartnerAnonymousStartApplyForLoan: "PartnerAnonymousStartApplyForLoan",
  PartnerAnonymousApplyForLoanOK: "PartnerAnonymousApplyForLoanOK",
  PartnerAnonymousApplyForLoanERROR: "PartnerAnonymousApplyForLoanERROR",

  PartnerAnonymousProductSelectorOpen: "PartnerAnonymousProductSelectorOpen",

  PartnerAnonymousGetLoansSubmit: "PartnerAnonymousGetLoansSubmit",
  PartnerAnonymousGetLoansOK: "PartnerAnonymousGetLoansOK",
  PartnerAnonymousGetLoansERROR: "PartnerAnonymousGetLoansERROR",

  PartnerAnonymousUpdateCustomerInfoSubmit:
    "PartnerAnonymousUpdateCustomerInfoSubmit",
  PartnerAnonymousUpdateCustomerInfoOK: "PartnerAnonymousUpdateCustomerInfoOK",
  PartnerAnonymousUpdateCustomerInfoERROR:
    "PartnerAnonymousUpdateCustomerInfoERROR",

  PartnerAnonymousGetPartnerDetailsSubmit:
    "PartnerAnonymousGetPartnerDetailsSubmit",
  PartnerAnonymousGetPartnerDetailsOK: "PartnerAnonymousGetPartnerDetailsOK",
  PartnerAnonymousGetPartnerDetailsERROR:
    "PartnerAnonymousGetPartnerDetailsERROR",
};

export const DashboardEvents = {
  DashboardOpen: "DashboardOpen",
  DashboardLoaded: "DashboardLoaded",
  DashboardOpenOK: "DashboardOpenOK",
  DashboardOpenERROR: "DashboardOpenERROR",
  // Cards
  DashboardProductRequestNew: "DashboardProductRequestNew",
  DashboardProductRequestContinue: "DashboardProductRequestContinue",
  DashboardProductRequestCancel: "DashboardProductRequestCancel",
  DashboardRejectedModalOpen: "DashboardRejectedModalOpen",
  DashboardRejectedModalOK: "DashboardRejectedModalOK",
  DashboardRejectedModalClose: "DashboardRejectedModalClose",
  DashboardLoanCardShowPaymentDetails: "DashboardLoanCardShowPaymentDetails",
  DashboardLoanCardBack: "DashboardLoanCardBack",
  DashboardOngoingRequestModalClose: "DashboardOngoingRequestModalClose",
};

export const PartnerProductRequestEvents = {
  PartnerDynamicFormStepOpen: (step: any) =>
    `PartnerDynamicFormStepOpen${step}`,
  PartnerDynamicFormStepNext: (step: any) =>
    `PartnerDynamicFormStepNext${step}`,
  PartnerDynamicFormStepBack: (step: any) =>
    `PartnerDynamicFormStepBack${step}`,
  PartnerDynamicFormValidationRequiredERROR:
    "PartnerDynamicFormValidationRequiredERROR",
  PartnerDynamicFormValidationInvalidERROR:
    "PartnerDynamicFormValidationInvalidERROR",
  PartnerDynamicFormSubmit: "PartnerDynamicFormSubmit",
  PartnerDynamicFormSubmitOK: "PartnerDynamicFormSubmitOK",
  PartnerDynamicFormSubmitERROR: "PartnerDynamicFormSubmitERROR",

  PartnerCheckOfferStatus: "PartnerCheckOfferStatus",
  PartnerCheckOfferStatusOK: "PartnerCheckOfferStatusOK",
  PartnerCheckOfferStatusERROR: "PartnerCheckOfferStatusERROR",

  PartnerProductOfferOpen: "PartnerProductOfferOpen",
  PartnerProductOfferAccept: "PartnerProductOfferAccept",
  PartnerProductOfferAcceptOK: "PartnerProductOfferAcceptOK",
  PartnerProductOfferAcceptERROR: "PartnerProductOfferAcceptERROR",

  PartnerProductOfferRejectModalOpen: "PartnerProductOfferRejectModalOpen",
  PartnerProductOfferRejectModalClose: "PartnerProductOfferRejectModalClose",
  PartnerProductOfferRejectSubmit: "PartnerProductOfferRejectSubmit",
  PartnerProductOfferRejectOK: "PartnerProductOfferRejectOK",
  PartnerProductOfferRejectERROR: "PartnerProductOfferRejectERROR",

  PartnerProductRequestRejectedPageOpen:
    "PartnerProductRequestRejectedPageOpen",

  PartnerFileRepoPageOpen: "PartnerFileRepoPageOpen",
  PartnerFileRepoPageComplete: "PartnerFileRepoPageComplete",
  PartnerFileRepoPageCompleteOK: "PartnerFileRepoPageCompleteOK",
  PartnerFileRepoPageCompleteERROR: "PartnerFileRepoPageCompleteERROR",

  PartnerFilesetPageOpen: "PartnerFilesetPageOpen",
  PartnerFilesetPageBack: "PartnerFilesetPageBack",

  PartnerFileUploadStart: "PartnerFileUploadStart",
  PartnerFileUpload: "PartnerFileUpload",
  PartnerFileUploadOK: "PartnerFileUploadOK",
  PartnerFileUploadERROR: "PartnerFileUploadERROR",
  PartnerFileUploadComplete: "PartnerFileUploadComplete",
  PartnerFileDownload: "PartnerFileDownload",
  PartnerFileDownloadOK: "PartnerFileDownloadOK",
  PartnerFileDownloadERROR: "PartnerFileDownloadERROR",

  PartnerFileDelete: "PartnerFileDelete",
  PartnerFileDeleteOK: "PartnerFileDeleteOK",
  PartnerFileDeleteERROR: "PartnerFileDeleteERROR",

  PartnerKycCreateSession: "PartnerKycCreateSession",
  PartnerErrorKycCreateSession: "PartnerErrorKycCreateSession",
  PartnerKycShowingStep0iFrame: "PartnerKycShowingStep0iFrame",
  PartnerKycEventInstanceFinalizedOk: "PartnerKycEventInstanceFinalizedOk",
  PartnerKycEvent: "PartnerKycEvent",
  PartnerKyciFrameStepsFinalized: "PartnerKyciFrameStepsFinalized",
  PartnerKyciFrameNextStep: "PartnerKyciFrameNextStep",
  PartnerKycCameraUnavailableInFacebookApp:
    "PartnerKycCameraUnavailableInFacebookApp",
  PartnerKycDeviceDoesNotHaveCamera: "PartnerKycDeviceDoesNotHaveCamera",
  PartnerKycGetUserMediaOK: "PartnerKycGetUserMediaOK",
  PartnerKycGetUserMediaERROR: "PartnerKycGetUserMediaERROR",

  PartnerSuccessPageOpen: "PartnerSuccessPageOpen",

  PartnerGetProductRequestInfoSubmit: "PartnerGetProductRequestInfoSubmit",
  PartnerGetProductRequestInfoOK: "PartnerGetProductRequestInfoOK",
  PartnerGetProductRequestInfoERROR: "PartnerGetProductRequestInfoERROR",
};

export const ExternalProductRequestEvents = {
  StartExternalProductRequestERROR: "StartExternalProductRequestERROR",
  StartExternalProductRequestOpen: "StartExternalProductRequestOpen",
  StartExternalProductRequestOK: "StartExternalProductRequestOK",

};

export const ProductRequestEvents = {
  StartProductRequestOpen: "StartProductRequestOpen",
  StartProductRequestSubmit: "StartProductRequestSubmit",
  StartProductRequestOK: "StartProductRequestOK",
  StartProductRequestERROR: "StartProductRequestERROR",

  DynamicFormNewVersionStepOpen: (step: any) =>
    `DynamicFormNewVersionStepOpen${step}`,
  DynamicFormNewVersionStepNext: (step: any) =>
    `DynamicFormNewVersionStepNext${step}`,
  DynamicFormNewVersionStepBack: (step: any) =>
    `DynamicFormNewVersionStepBack${step}`,
  DynamicFormOldVersionStepOpen: (step: any) =>
    `DynamicFormOldVersionStepOpen${step}`,
  DynamicFormOldVersionStepNext: (step: any) =>
    `DynamicFormOldVersionStepNext${step}`,
  DynamicFormOldVersionStepBack: (step: any) =>
    `DynamicFormOldVersionStepBack${step}`,
  DynamicFormNewVersionValidationRequiredERROR:
    "DynamicFormNewVersionValidationRequiredERROR",
  DynamicFormNewVersionValidationInvalidERROR:
    "DynamicFormNewVersionValidationInvalidERROR",
  DynamicFormOldVersionValidationRequiredERROR:
    "DynamicFormOldVersionValidationRequiredERROR",
  DynamicFormOldVersionValidationInvalidERROR:
    "DynamicFormOldVersionValidationInvalidERROR",
  DynamicFormNewVersionSubmit: "DynamicFormNewVersionSubmit",
  DynamicFormNewVersionSubmitOK: "DynamicFormNewVersionSubmitOK",
  DynamicFormNewVersionSubmitERROR: "DynamicFormNewVersionSubmitERROR",
  DynamicFormOldVersionSubmit: "DynamicFormOldVersionSubmit",
  DynamicFormOldVersionSubmitOK: "DynamicFormOldVersionSubmitOK",
  DynamicFormOldVersionSubmitERROR: "DynamicFormOldVersionSubmitERROR",
  DynamicFormNewVersionRendered: "DynamicFormNewVersionRendered",
  DynamicFormOldVersionRendered: "DynamicFormOldVersionRendered",

  CheckOfferStatus: "CheckOfferStatus",
  CheckOfferStatusOK: "CheckOfferStatusOK",
  CheckOfferStatusERROR: "CheckOfferStatusERROR",

  ProductOfferOpen: "ProductOfferOpen",
  ProductOfferAccept: "ProductOfferAccept",
  ProductOfferAcceptOK: "ProductOfferAcceptOK",
  ProductOfferAcceptERROR: "ProductOfferAcceptERROR",

  ProductOfferRejectModalOpen: "ProductOfferRejectModalOpen",
  ProductOfferRejectModalClose: "ProductOfferRejectModalClose",
  ProductOfferRejectSubmit: "ProductOfferRejectSubmit",
  ProductOfferRejectOK: "ProductOfferRejectOK",
  ProductOfferRejectERROR: "ProductOfferRejectERROR",

  ProductRequestRejectedPageOpen: "ProductRequestRejectedPageOpen",
  ProductRequestRejectSubmit: "ProductRequestRejectSubmit",
  ProductRequestRejectOK: "ProductRequestRejectOK",
  ProductRequestRejectERROR: "ProductRequestRejectERROR",

  FileRepoPageOpen: "FileRepoPageOpen",
  FileRepoPageComplete: "FileRepoPageComplete",
  FileRepoPageCompleteOK: "FileRepoPageCompleteOK",
  FileRepoPageCompleteERROR: "FileRepoPageCompleteERROR",

  FilesetPageOpen: "FilesetPageOpen",
  FilesetPageBack: "FilesetPageBack",

  FileUploadStart: "FileUploadStart",
  FileUpload: "FileUpload",
  FileUploadOK: "FileUploadOK",
  FileUploadERROR: "FileUploadERROR",
  FileUploadComplete: "FileUploadComplete",
  FileDownload: "FileDownload",
  FileDownloadOK: "FileDownloadOK",
  FileDownloadERROR: "FileDownloadERROR",
  FileInfoBeforeUpload: "FileInfoBeforeUpload",

  FileDelete: "FileDelete",
  FileDeleteOK: "FileDeleteOK",
  FileDeleteERROR: "FileDeleteERROR",

  GetProductRequestInfoError: "GetProductRequestInfoError",
  GetCustomerAndUserInfoError: "GetCustomerAndUserInfoError",
};

export const KycEvents = {
  KycCreateSession: "KycCreateSession",
  ErrorKycCreateSession: "ErrorKycCreateSession",
  KycShowingStep0iFrame: "KycShowingStep0iFrame",
  KycEventInstanceFinalizedOk: "KycEventInstanceFinalizedOk",
  KycEvent: "KycEvent",
  KyciFrameStepsFinalized: "KyciFrameStepsFinalized",
  KyciFrameNextStep: "KyciFrameNextStep",
  KycCameraUnavailableInFacebookApp: "KycCameraUnavailableInFacebookApp",
  KycDeviceDoesNotHaveCamera: "KycDeviceDoesNotHaveCamera",
  KycGetUserMediaOK: "KycGetUserMediaOK",
  KycGetUserMediaERROR: "KycGetUserMediaERROR",
};

export const SystemEvents = {
  RouteChanged: "RouteChanged",
  AuthCallback: "AuthCallback",
  AuthCallbackSuccess: "AuthCallbackSuccess",
  AuthCallbackFailed: "AuthCallbackFailed",
  AuthTokenOverriden: "AuthTokenOverriden",
  AuthAccessTokenNotFound: "AuthAccessTokenNotFound",
  AuthSilentRenewError: "AuthSilentRenewError",
  UtmParametersArrayFromLocalStorage: "UtmParametersArrayFromLocalStorage",
  LegacyUtmParametersFromLocalStorage: "LegacyUtmParametersFromLocalStorage",
};

export const UserProfileEvents = {
  UserForgotPasswordButton: "UserForgotPasswordButton",
  GetUserProfileError: "GetUserProfileError",
};

export const ProductSelectorEvents = {
  ProductSelectorOpen: "ProductSelectorOpen",
  ProductSelectorSubmit: "ProductSelectorSubmit",
  ProductSelectorSubmitOK: "ProductSelectorSubmitOK",
  ProductSelectorSubmitERROR: "ProductSelectorSubmitERROR",
  ProductSelectorProductUndefinedModalClose:
    "ProductSelectorProductUndefinedModalClose",
};

export const FinancingSolutionEvents = {
  FinancingSolutionProductRequestNew: "FinancingSolutionProductRequestNew",
  ChangeProductSelectorAnswers: "ChangeProductSelectorAnswers",
  FinancingSolutionCardBeforeRender: "FinancingSolutionCardBeforeRender",
  FinancingSolutionError: "FinancingSolutionError",
  FilboSplitClientRequiresOtherOptions: "FilboSplitClientRequiresOtherOptions",
};

export const ApplicationGeneralEvents = {
  NotFoundPageOpen: "NotFoundPageOpen",
};
