import React, { useEffect } from "react";
import classNames from "classnames";
import { useNotification } from "./NotificationProvider";

export type ToastProps = {
  children: React.ReactNode;
  id: any;
  style: any;
  status: any;
  duration: any;
  props?: any;
  content?: any;
};

const Toast = ({ children, id, status, duration = 3000 }: ToastProps) => {
  const { removeToast } = useNotification();

  useEffect(() => {
    const timer = setTimeout(() => {
      removeToast(id);
    }, duration);

    return () => {
      clearTimeout(timer);
    };
  }, [id, removeToast, duration]);

  return (
    <div
      className={classNames("notification", {
        "notification-error": status === "error",
        "notification-success": status === "success",
      })}
    >
      {children}
    </div>
  );
};

export default Toast;
