import React from "react";
import ReactDOM from "react-dom";
import App from "./modules/App/App";
import "./styles/index.scss";

ReactDOM.render(
  <App />,

  document.getElementById("root")
);
