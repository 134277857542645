import React from "react";
import { createPortal } from "react-dom";
import { useTransition, animated } from "react-spring";

import Notification, { ToastProps } from "./Notification";

type ContainerProps = {
  toasts: ToastProps[];
};

const NotificationContainer = ({ toasts }: ContainerProps) => {
  const transitions = useTransition(toasts, (toast) => toast.id, {
    from: { transform: "translate3d(0,-5rem,0)" },
    enter: { transform: "translate3d(0,0px,0)" },
    leave: { transform: "translate3d(0,-5rem,0)" },
  });

  return createPortal(
    <div className="notification-container">
      {transitions.map(({ item, key, props }) => {
        return (
          <animated.div key={key} style={props}>
            <Notification id={item.id} {...item.props}>
              {item.content}
            </Notification>
          </animated.div>
        );
      })}
    </div>,
    document.body
  );
};

export default NotificationContainer;
