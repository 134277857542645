import { API_ERROR_CODE, PRODUCT_SELECTOR_QUESTIONS } from "./const";

export const scrollElementToTop = () => {
  document.querySelector(".scroll-anchor")?.scrollIntoView();
};

export const deepCopy = (inObject: any) => {
  let outObject, value, key;
  if (typeof inObject !== "object" || inObject === null) {
    return inObject;
  }
  outObject = Array.isArray(inObject) ? [] : {};
  for (key in inObject) {
    value = inObject[key];
    outObject[key] = deepCopy(value);
  }
  return outObject;
};

export function IsEligibilityMissing(userProfile: any) {
  if (
    !PRODUCT_SELECTOR_QUESTIONS.every((x) =>
      userProfile.customerMappings[0].customer.customerEligibilityValues?.find(
        (y: any) => y.businessMeaning === x.businessMeaning
      )
    )
  )
    return true;
  return false;
}

export interface IUtmLocalStorage {
  storedCampaignParameter: string | null;
  storedSourceParameter: string | null;
  storedMediumParameter: string | null;
  storedTermParameter: string | null;
  storedContentParameter: string | null;
  storedReferrerParameter: string | null;
  expiryTime: number;
}

export const mapErroCodeToMessage = (errorCode?: string) => {
  let errorDisplayed = false;

  Object.entries(API_ERROR_CODE).forEach(([key, value]) => {
    if (key === errorCode && !errorDisplayed) {
      window.notifications.notifyError(value);
      errorDisplayed = true;
    }
  });

  if (!errorDisplayed) {
    window.notifications.notifyError(API_ERROR_CODE.Default);
  }
};

export const pushInfoToDataLayer = (infoAsJsonFormat: any) => {
  // @ts-ignore
  window.dataLayer = window.dataLayer || [];
  // @ts-ignore
  window.dataLayer.push(infoAsJsonFormat);
};

export interface IUtmParameters {
  utmCampaign: string | null;
  utmSource: string | null;
  utmMedium: string | null;
  utmTerm: string | null;
  utmContent: string | null;
  referrer: string | null;
  addedTime: number;
  expiryTime: number;
}

export interface IOnDemandCampaign {
  onDemandCampaign: string;
  expiryTime: number;
}

export const getOnDemandCampaignFromLocalStorage = () => {
  const onDemandCampaignLocalStorageKey = "onDemandCampaign";

  let onDemandCampaignFromLocalStorage = window.localStorage.getItem(
    onDemandCampaignLocalStorageKey
  );
  let parsedOnDemandCampaign =
    onDemandCampaignFromLocalStorage !== null &&
    onDemandCampaignFromLocalStorage !== "undefined" &&
    onDemandCampaignFromLocalStorage !== "null"
      ? (JSON.parse(onDemandCampaignFromLocalStorage) as IOnDemandCampaign)
      : undefined;

  if (
    parsedOnDemandCampaign?.expiryTime &&
    parsedOnDemandCampaign.expiryTime < Date.now()
  ) {
    window.localStorage.removeItem(onDemandCampaignLocalStorageKey);
    return;
  }

  return parsedOnDemandCampaign?.onDemandCampaign;
};

export interface ILeadSource {
  leadSource: string;
  expiryTime: number;
}

export const getLeadSourceFromLocalStorage = () => {
  const leadSourceLocalStorageKey = "leadSource";

  let leadSourceFromLocalStorage = window.localStorage.getItem(
    leadSourceLocalStorageKey
  );
  let parsedLeadSource =
    leadSourceFromLocalStorage !== null &&
    leadSourceFromLocalStorage !== "undefined" &&
    leadSourceFromLocalStorage !== "null"
      ? (JSON.parse(leadSourceFromLocalStorage) as ILeadSource)
      : undefined;

  if (
    parsedLeadSource?.expiryTime &&
    parsedLeadSource.expiryTime < Date.now()
  ) {
    window.localStorage.removeItem(leadSourceLocalStorageKey);
    return;
  }

  return parsedLeadSource?.leadSource;
};

export const decideWhatUtmParametersToUse = (
  receivedUtmParameters: IUtmParameters | null
) => {
  const utmParametersArrayLocalStorageKey = "utmParametersArray";
  const paidSources = [
    "Facebook",
    "Google",
    "Instagram",
    "LinkedIn",
    "2Performant",
  ];

  let utmParametersArrayFromLocalStorage = window.localStorage.getItem(
    utmParametersArrayLocalStorageKey
  );
  let parsedUtmParametersArray =
    utmParametersArrayFromLocalStorage !== null &&
    utmParametersArrayFromLocalStorage !== "undefined" &&
    utmParametersArrayFromLocalStorage !== "null"
      ? (JSON.parse(
          utmParametersArrayFromLocalStorage
        ) as Array<IUtmParameters>)
      : new Array<IUtmParameters>();

  if (receivedUtmParameters)
    parsedUtmParametersArray?.push(receivedUtmParameters);

  let legacyUtmParametersFromLocalStorage =
    window.localStorage.getItem("utmParameters");

  let legacyUtmParameters =
    legacyUtmParametersFromLocalStorage !== null &&
    legacyUtmParametersFromLocalStorage !== "undefined" &&
    legacyUtmParametersFromLocalStorage !== "null"
      ? (JSON.parse(legacyUtmParametersFromLocalStorage) as IUtmLocalStorage)
      : null;

  if (legacyUtmParameters) {
    window.analytics?.trackEvent(
      window.analytics.events.LegacyUtmParametersFromLocalStorage,
      {
        legacyUtmParametersFromLocalStorage:
          legacyUtmParametersFromLocalStorage,
      }
    );

    let parsedLegacyExistingUTMParameters: IUtmParameters = {
      utmCampaign: legacyUtmParameters.storedCampaignParameter,
      utmSource: legacyUtmParameters.storedSourceParameter,
      utmContent: legacyUtmParameters.storedContentParameter,
      utmMedium: legacyUtmParameters.storedMediumParameter,
      utmTerm: legacyUtmParameters.storedTermParameter,
      referrer: legacyUtmParameters.storedReferrerParameter,
      addedTime: Date.now() - 86_400_000,
      expiryTime: legacyUtmParameters.expiryTime,
    };
    parsedUtmParametersArray.push(parsedLegacyExistingUTMParameters);

    window.localStorage.removeItem("utmParameters");
  }

  parsedUtmParametersArray = parsedUtmParametersArray
    ?.filter((utmParameters) => utmParameters.expiryTime >= Date.now())
    .sort((a, b) => {
      return a.addedTime - b.addedTime;
    });
  window.localStorage.setItem(
    utmParametersArrayLocalStorageKey,
    JSON.stringify(parsedUtmParametersArray)
  );

  let firstUtmParameters = parsedUtmParametersArray?.[0];
  let areFirstUtmParametersDirectLink =
    firstUtmParameters?.utmSource?.toLocaleLowerCase() ===
    "directlink".toLocaleLowerCase();
  let areFirstUtmParameters2Performant =
    firstUtmParameters?.utmSource?.toLocaleLowerCase() ===
    "2Performant".toLocaleLowerCase();
  if (areFirstUtmParameters2Performant || areFirstUtmParametersDirectLink) {
    return firstUtmParameters;
  }

  let lastPaidUtmParameters = parsedUtmParametersArray?.findLast(
    (utmParameters) =>
      paidSources.some(
        (paidSource) =>
          paidSource.trim().toLocaleLowerCase() ===
          utmParameters.utmSource?.trim()?.toLocaleLowerCase()
      )
  );
  if (lastPaidUtmParameters) {
    return lastPaidUtmParameters;
  }

  return parsedUtmParametersArray?.[parsedUtmParametersArray.length - 1];
};

export function isRunningFromWebView() {
  const navigator = window.navigator
  const userAgent = navigator.userAgent
  const normalizedUserAgent = userAgent.toLowerCase()

  const isIos = /ip(ad|hone|od)/.test(normalizedUserAgent) || (navigator.platform === "MacIntel" && navigator.maxTouchPoints > 1)
  const isAndroid = /android/.test(normalizedUserAgent)
  const isSafari = /safari/.test(normalizedUserAgent)
  const isWebview = (isAndroid && /; wv\)/.test(normalizedUserAgent)) || (isIos && !isSafari)
  return isWebview
};
