import { User, UserManager } from "oidc-client";
import { ProductRequestServiceUserManager } from "./ProductRequestServiceUserManager";
import { ProductRequestServiceUser } from "./ProductRequestServiceUser";
import { isRunningFromWebView } from "modules/Shared/utils";

export type MasterUser = User | ProductRequestServiceUser;

export class MasterUserManager {
    oidcUserManager: UserManager | undefined;
    productReqServiceUserManager: ProductRequestServiceUserManager | undefined;

    constructor(oidcUserManager?: UserManager | undefined,
        productReqServiceUserManager?: ProductRequestServiceUserManager | undefined) {
        this.oidcUserManager = oidcUserManager;
        this.productReqServiceUserManager = productReqServiceUserManager;
    }

    initUserManager = (): MasterUserManager => {
        let oidcUserManager: UserManager | undefined = undefined;
        let productRequestUserManager: ProductRequestServiceUserManager | undefined = undefined;

        if (isRunningFromWebView()) {
            productRequestUserManager = new ProductRequestServiceUserManager();
        }
        else {
            oidcUserManager = new UserManager({
                authority: process.env.REACT_APP_AUTH_STS_AUTHORITY,
                client_id: process.env.REACT_APP_AUTH_CLIENT_ID,
                redirect_uri: `${process.env.REACT_APP_AUTH_CLIENT_ROOT}/callback`,
                silent_redirect_uri: `${process.env.REACT_APP_AUTH_CLIENT_ROOT}/silentrenew`,
                post_logout_redirect_uri: `${process.env.REACT_APP_AUTH_CLIENT_ROOT}/logout`,
                response_type: "code",
                scope: process.env.REACT_APP_AUTH_CLIENT_SCOPE,

                includeIdTokenInSilentRenew: true,
                loadUserInfo: true,
                automaticSilentRenew: true,
                metadata: {
                    issuer: process.env.REACT_APP_AUTH_STS_AUTHORITY,
                    jwks_uri:
                        process.env.REACT_APP_AUTH_STS_AUTHORITY +
                        "/.well-known/openid-configuration/jwks",
                    authorization_endpoint:
                        process.env.REACT_APP_AUTH_STS_AUTHORITY + "/connect/authorize",
                    token_endpoint:
                        process.env.REACT_APP_AUTH_STS_AUTHORITY + "/connect/token",
                    userinfo_endpoint:
                        process.env.REACT_APP_AUTH_STS_AUTHORITY + "/connect/userinfo",
                    end_session_endpoint:
                        process.env.REACT_APP_AUTH_STS_AUTHORITY + "/connect/endsession",
                    check_session_iframe:
                        process.env.REACT_APP_AUTH_STS_AUTHORITY + "/connect/checksession",
                    revocation_endpoint:
                        process.env.REACT_APP_AUTH_STS_AUTHORITY + "/connect/revocation",
                    introspection_endpoint:
                        process.env.REACT_APP_AUTH_STS_AUTHORITY + "/connect/introspect",
                },
            });

            oidcUserManager.events.addSilentRenewError((error: Error) => {
                window.analytics?.trackEvent(window.analytics.events.AuthSilentRenewError, { error: error });
                console.log(error);
            });

            oidcUserManager.events.addUserLoaded(function (user) {
                oidcUserManager?.getUser().then(function () { });
            });

            oidcUserManager.events.addUserUnloaded(() => { });
            oidcUserManager.events.addUserSessionChanged(() => { });
        }

        return new MasterUserManager(oidcUserManager, productRequestUserManager);

    }

    async getUser(): Promise<MasterUser | null | undefined> {
        if (isRunningFromWebView() && this.productReqServiceUserManager) {
            return await this.productReqServiceUserManager.getUser();
        }

        return await this.oidcUserManager?.getUser();
    }

    async signinRedirectCallback(): Promise<User | undefined> {
        return await this.oidcUserManager?.signinRedirectCallback();
    }

    async signinRedirect(redirectUrl: string | undefined, username: string | undefined): Promise<void> {
        return await this.oidcUserManager?.signinRedirect({
            state: { url: redirectUrl ? redirectUrl : window.location.pathname },
            login_hint: username ?? ""
        });
    }

    async signoutRedirect(): Promise<void> {
        return await this.oidcUserManager?.signoutRedirect();
    }

    async storeUser(user: User): Promise<void> {
        return await this.oidcUserManager?.storeUser(user);
    }

    isUserExpired(user: MasterUser): boolean {
        const expired = (user as User).expired;
        return expired;
    }
}