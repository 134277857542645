export class ProductRequestServiceUser {
    constructor(userSettings: ProductRequestServiceUserUserSettings) {
        this.access_token = userSettings.access_token;
        this.expires_at = userSettings.expires_at;
        this.token_type = userSettings.token_type;
        this.scope = userSettings.scope;
        this.issuer = userSettings.issuer;
        this.audience = userSettings.audience;
        this.subjectIdentifier = userSettings.subjectIdentifier;
    }

    access_token: string | undefined;
    expires_at: number | undefined;
    token_type: string | undefined;
    scope: string | undefined;
    issuer: string | undefined;
    audience: string | undefined;
    subjectIdentifier: string | undefined;
}

export interface ProductRequestServiceUserUserSettings {
    access_token: string | undefined;
    expires_at: number;
    token_type: string;
    scope: string;
    issuer: string;
    audience: string;
    subjectIdentifier: string;
}