import jwt_decode from "jwt-decode";
import { ProductRequestServiceUser, ProductRequestServiceUserUserSettings } from "./ProductRequestServiceUser";

export class ProductRequestServiceUserManager {
    getProductRequestTokenFromLocalStorage() {
        return window.localStorage.getItem("productRequestToken");
    }

    async getUser(): Promise<ProductRequestServiceUser> {
        const productRequestToken = this.getProductRequestTokenFromLocalStorage();
        let decoded_access_token: any;

        if (productRequestToken != null) {
            decoded_access_token = jwt_decode(productRequestToken);
        }

        const productRequestServiceUserSettings: ProductRequestServiceUserUserSettings = {
            access_token: productRequestToken !== null ? productRequestToken : undefined,
            expires_at: decoded_access_token?.exp,
            token_type: "Bearer",
            scope: "product-request-api",
            issuer: decoded_access_token?.iss,
            audience: decoded_access_token?.aud,
            subjectIdentifier: decoded_access_token?.sub
        }

        const productRequestUser = new ProductRequestServiceUser(productRequestServiceUserSettings);
        return productRequestUser;
    }
}